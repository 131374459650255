import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/jenkins/work/workspace/_complaint-ui-service-ci_develop/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import { mapGetters } from 'vuex';
import { hiddenForUser } from '@/utils/index';

// const workOrderTypeOptions = ['100000000', '100000001', '100000003', '100000004', '100000002'];

export default {
  name: 'SidebarItem',
  components: {
    Item: Item,
    AppLink: AppLink
  },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    filterTypes: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {
      // workOrderType: {
      //   100000000: '非会员单增减积分',
      //   100000001: '新建会员',
      //   100000002: '其他',
      //   100000003: '修改会员手机号/合并会员账号 ',
      //   100000004: '门店换货补券'
      // },
      // checkAll: false,
      // checkedTypes: [],
      // types: workOrderTypeOptions,
      // getHiddenForUser: false,
      // checkboxDisabled: false
    };
  },
  computed: _objectSpread({}, mapGetters(['roleName'])),
  watch: {
    $route: function $route(to) {
      // if (to.path === '/dashboard') {
      //   this.checkboxDisabled = false;
      // } else {
      //   this.checkboxDisabled = true;
      // }
    },
    filterTypes: function filterTypes(val) {
      // if (val === '-1') {
      //   // this.isIndeterminate = true;
      //   // this.checkAll = true;
      //   // this.checkedTypes = workOrderTypeOptions;
      // } else {
      //   // this.isIndeterminate = false;
      //   this.checkAll = false;
      //   this.checkedTypes = val ? val.split(',') : [];
      // }
    }
  },
  created: function created() {
    // 刷新时获取router参数
    // const type = this.$route.query.type;
    // if (type) {
    //   if (type.indexOf(',') === -1 && type === '-1') {
    //     this.checkedTypes = workOrderTypeOptions;
    //     // this.isIndeterminate = true;
    //     this.checkAll = true;
    //   } else {
    //     this.checkedTypes = type ? type.split(',') : [];
    //     // this.isIndeterminate = false;
    //     this.checkAll = false;
    //   }
    // } else {
    //   // this.checkAll = true;
    //   // this.checkedTypes = workOrderTypeOptions;
    // }
  },
  mounted: function mounted() {
    this.getHiddenForUser = !hiddenForUser(this.roleName);
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = _objectSpread(_objectSpread({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    // handleCheckAllChange(val) {
    //   this.checkedTypes = val ? workOrderTypeOptions : [];
    //   // this.isIndeterminate = false;
    //   this.queyrWorkOrderList(['-1']);
    // },
    // handleCheckedTypesChange(value) {
    //   const checkedCount = value.length;
    //   this.checkAll = checkedCount === this.types.length;
    //   // this.isIndeterminate =
    //   //   checkedCount > 0 && checkedCount < this.types.length;
    //   if (this.checkAll) {
    //     this.queyrWorkOrderList(['-1']);
    //   } else {
    //     this.queyrWorkOrderList(value);
    //   }
    // },
    // queyrWorkOrderList(val) {
    //   const queryData = {
    //     type: val.length ? val.join() : '-1'
    //   };
    //   sessionStorage.setItem('menuclick', 1);
    //   this.$router.push({ path: '/dashboard', query: queryData });
    // },
    backToHome: function backToHome(name, e) {
      var workPaginationOpt = sessionStorage.getItem('workPaginationOpt');
      if (name !== 'Dashboard' && workPaginationOpt) {
        sessionStorage.removeItem('workPaginationOpt');
      }
      if (this.$route.path === '/dashboard' && name === 'Dashboard') {
        workPaginationOpt && sessionStorage.removeItem('workPaginationOpt');
        this.$router.push({
          path: '/dashboard',
          query: {
            t: +new Date() // 保证每次点击路由的query项都是不一样的，确保会重新刷新view
          }
        });
        // this.$router.go({
        //   path: '/dashboard',
        //   force: true
        // });
      }

      // if (name === 'Dashboard' && Object.keys(this.$route.query).length === 0 && workPaginationOpt) {
      //   if (JSON.parse(workPaginationOpt).type !== -1) {
      //     // 触发视图刷新
      //     this.$router.replace({ path: '/dashboard', query: { type: '-1' }});
      //     // 重置工单查询
      //     sessionStorage.setItem('menuclick', 1);
      //     sessionStorage.removeItem('workPaginationOpt');
      //   }
      // }

      // const val = false;
      // this.checkAll = false;
      // this.checkedTypes = val ? workOrderTypeOptions : [];
    }
  }
};