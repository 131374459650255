import "/home/jenkins/work/workspace/_complaint-ui-service-ci_develop/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/jenkins/work/workspace/_complaint-ui-service-ci_develop/node_modules/core-js/modules/es6.promise.js";
import "/home/jenkins/work/workspace/_complaint-ui-service-ci_develop/node_modules/core-js/modules/es6.object.assign.js";
import "/home/jenkins/work/workspace/_complaint-ui-service-ci_develop/node_modules/core-js/modules/es7.promise.finally.js";
import 'babel-polyfill'; // 兼容IE

import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'production') {
  // 线上环境使用mockjs，但控制台network会看不到请求url
  // const { mockXHR } = require('../mock');
  // mockXHR();
}

// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale: locale
});
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;
import MsalPlugin from '@/utils/msal-browser/msalPlugin';
Vue.use(MsalPlugin);
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});